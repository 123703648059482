import { JUSYOU_REKI } from "~/constants/contest";
import type {
  BlogDetail,
  BlogList,
  ContestDetail,
  Letter,
  LetterAvg,
  Plan,
  PlanListQuery,
  PriceRange,
  Sekou,
} from "~/types";
import { alphabetToCity } from "~/utils/sekou/area";
import { getReviewDetailPath } from "~/utils";
// meta description
const getDescription = (description: string) => {
  return {
    name: "description",
    content: description,
  };
};

// ページ番号テキスト
const getOffsetText = (off: number) => (off > 0 ? `${off + 1}ページ` : "");

// TOP web\pages\index.vue
function useHeadHomePage(params: {
  open: number;
  carport: number;
  hei: number;
}) {
  return useHead(() => {
    return {
      title: "激安外構庭リフォーム工事",
      meta: [
        getDescription(
          `見積無料・10年保証付で工事後も安心。おしゃれオープン外構${params.open}件・激安カーポート${params.carport}件・目隠し塀${params.hei}件公開。関西・中京・関東・九州エリア/全国送料無料ネット通販`,
        ),
      ],
    };
  });
}
// ブログ 一覧 web\pages\BLOG\[blog_id]\index.vue , web\pages\BLOG\[blog_id]\tag.php\[tag].vue
function useHeadBlog(blog: BlogList) {
  return useHead(() => {
    return {
      title: blog.blog_name,
      meta: [getDescription(blog.exp || blog.blog_name)],
    };
  });
}

// ブログ 詳細 web\pages\BLOG\[blog_id]\blog.php\[blog_num].vue
function useHeadBlogDetail(blog: BlogDetail) {
  return useHead(() => {
    return {
      title: blog.blog_title,
      meta: [
        getDescription(`${blog.blog_name}ブログ、${blog.blog_title}の記事です`),
      ],
    };
  });
}

// コンテスト 一覧 web\pages\contents.php\contest.vue
function useHeadContest() {
  return useHead(() => {
    return {
      title: `LIXIL、YKKAPなど大手メーカー外構デザインコンテスト${JUSYOU_REKI}年連続受賞`,
      meta: [
        getDescription(`ファミリー庭園の施工例コンテスト受賞歴を紹介します`),
      ],
    };
  });
}

// コンテスト 一覧 年度別 web\components\contest\page\Year.vue
function useHeadContestYear(year?: string) {
  return useHead({
    title: `${year ? `${year}年の` : ""}コンテスト受賞作品`,
    meta: [
      getDescription(
        `ファミリー庭園${year ? `${year}年度` : ""}のコンテスト受賞作品を紹介します `,
      ),
    ],
  });
}

// コンテスト詳細
function useHeadContestDetail(year: string, data: ContestDetail) {
  return useHead(() => {
    const title = `${year}年${data.contest + data.bumon}受賞作品 | ${data.award.name}`;
    return {
      title: title,
      meta: [
        getDescription(
          `${data.bumon}の${data.award.name}作品です。${data.text ? `施工のポイントは、${data.text}` : ""}`,
        ),
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:image",
          content: `https://static.famitei.co.jp${data.images[0]}`,
        },
      ],
    };
  });
}

// 施工例 一覧 web\components\sekou\list\Index.vue
function useHeadSekou(count: number, off: number) {
  return useHead(() => {
    return {
      title: `外構・庭・リフォーム工事の施工例(${count}件)`,
      meta: [
        getDescription(
          `外構、お庭、リフォーム工事の施工例一覧ページです。${getOffsetText(off)}おしゃれな新築オープン外構工事や目隠しフェンス、玄関アプローチなどの施工例をご覧いただけます。ファミリー庭園ではお客様のご要望に合わせた外構プランをご提案しています。本当に必要なものだけを質の高い工事・低価格でご提供します。ファミリー庭園【正規】`,
        ),
      ],
    };
  });
}

// 施工例 一覧 固有Descriptionあり web\components\sekou\list\Index.vue
function useHeadSekouWithMeta(
  count: number,
  off: number,
  meta: {
    title?: string;
    description?: string;
    h1txt?: string;
  },
) {
  return useHead(() => {
    let description = "";
    if (meta.description) {
      description = meta.description?.replace(/%cnt%/, count.toString());
      description = `${meta.h1txt?.replace(
        "　",
        "",
      )}一覧です。${getOffsetText(off)} ${description}`;
    } else {
      description = `施工例一覧ページです。${getOffsetText(off)} ファミリー庭園(関西7店舗)外構エクステリア工事の施工例公開件数${count}件公開中件で日本最大数。見積無料・10年保証・契約者には木製ブランコプレゼント。`;
    }
    return {
      title:
        meta.title?.replace(/%cnt%/, count.toString()) ||
        `外構・庭・リフォーム工事の施工例(${count}件)`,
      meta: [getDescription(description)],
    };
  });
}

// 施工例 エリア別 一覧 web\components\sekou\list\Area.vue
function useHeadSekouArea(
  pref: string,
  city: string,
  count: number,
  off: number,
) {
  return useHead(() => {
    return {
      title: `${pref}${alphabetToCity(city)}の外構施工例(${count}件)`,
      meta: [
        getDescription(
          `${pref}${alphabetToCity(city)}の施工例一覧ページです。${getOffsetText(off)}おしゃれな新築オープン外構工事や目隠しフェンス、玄関アプローチなどの施工例をご覧いただけます。ファミリー庭園ではお客様のご要望に合わせた外構プランをご提案しています。本当に必要なものだけを質の高い工事・低価格でご提供します。ファミリー庭園【正規】`,
        ),
      ],
    };
  });
}

// 施工例 キーワード web\pages\sekou\keyword.php.vue
function useHeadSekouKeyword(
  params: () => {
    keyword: string;
    count: number;
    off: number;
    meta?: {
      title?: string;
      description?: string;
      h1txt?: string;
    };
  },
) {
  return useHead(() => {
    const { keyword, count, off, meta } = params();
    return {
      title: `「${keyword}」の外構施工例(${count}件)`,
      meta: [
        getDescription(
          `「${keyword}」の施工例一覧ページです。${getOffsetText(off)}${meta?.description ?? "おしゃれな新築オープン外構工事や目隠しフェンス、玄関アプローチなどの施工例をご覧いただけます。ファミリー庭園ではお客様のご要望に合わせた外構プランをご提案しています。本当に必要なものだけを質の高い工事・低価格でご提供します。ファミリー庭園【正規】"}`,
        ),
      ],
    };
  });
}

// 施工例 詳細 web\components\sekou\detail\Index.vue
function useHeadSekouDetail(sekou: Sekou) {
  return useHead(() => {
    let date = "";
    if (sekou.s_date) {
      const [year, month] = sekou.s_date.split("-");
      if (year && month) {
        date = `${year}年${month}月`;
      }
    }
    return {
      title: `${sekou.comment}の外構施工例（現場番号：${sekou.sekou_cd}）`,
      meta: [
        getDescription(
          `${sekou.area_1 + sekou.area_2} ${sekou.title_1} ${date} | 外構・リフォーム工事のファミリー庭園`,
        ),
      ],
    };
  });
}

// レビュー 一覧 web\pages\e_list.vue
function useHeadReview(count: number) {
  return useHead(() => {
    return {
      title: "外構・リフォーム工事をされたお客様の声・評判・口コミ",
      meta: [
        getDescription(
          `実際にファミリー庭園で外構工事をされたお客様からのレビューや感想のお手紙。${count}件公開中。`,
        ),
      ],
    };
  });
}

// レビュー 詳細 web\pages\letter_e.php\l[kouji_cd].vue
function useHeadReviewDetail(data: Letter, domain: string) {
  return useHead(() => {
    return {
      title: `外構・リフォーム工事をされたお客様からの手紙・評判・口コミ - ${data.address} ${data.k_ini}様`,
      link: [
        {
          rel: "canonical",
          href: `${domain}${getReviewDetailPath(parseInt(data.kouji_cd))}`,
        },
      ],
      meta: [
        getDescription(
          `ファミリー庭園で施工された${data.address} ${data.k_ini}様からのご意見・ご感想。お客様の生の声・評判。`,
        ),
      ],
    };
  });
}

// プラン 一覧 web\pages\plan\index.vue
function useHeadPlan(
  params: () => {
    conditions: string[];
    attrs: string[];
    query: PlanListQuery;
    priceRange?: PriceRange;
    letterAvg?: LetterAvg;
  },
) {
  return useHead(() => {
    const { conditions, attrs, query, priceRange, letterAvg } = params();
    const title =
      conditions.length || attrs.length
        ? `${conditions.join("")}${attrs.join("の")}`
        : "外構・リフォーム";

    const description = priceRange
      ? `【公式】${priceRange.from ? priceRange.from + "万～" : ""}
    ${priceRange.to ? priceRange.to + "万以下" : ""}
    の外構工事プラン一覧${getOffsetText(query.off)}。${priceRange.description}ファミリー庭園は多様なプランや見積りを無料でご提案。クチコミ平均★${letterAvg?.avg.enq_1}、工事品質には自信があります！`
      : `${title}のプラン事例を紹介します。${getOffsetText(query.off)}`;
    return {
      title: title + "のプラン事例を紹介",
      meta: [getDescription(description)],
      link: [
        {
          rel: "canonical",
          href: `https://www.famitei.co.jp/plan?range=${query.range}&tsubo=${query.tsubo}&kuruma=${query.kuruma}&houi=${query.houi}&attr=${query.attr}&off=${query.off}&order=${query.order}&asc=${query.asc}`,
        },
      ],
    };
  });
}

// プラン 詳細 web\pages\plan\detail\[plan_cd].vue
function useHeadPlanDetail(plan: Plan) {
  return useHead(() => {
    return {
      title: plan.title ?? `プラン詳細 No.${plan.plan_cd}`,
      meta: [
        getDescription(
          plan.comment ??
            `${addComma(Math.round(plan.account / 10000))}万円～の${
              plan.ktype?.name
            }プラン事例詳細`,
        ),
      ],
    };
  });
}

export function useHeadTemplate() {
  return {
    useHeadBlog,
    useHeadBlogDetail,
    useHeadContest,
    useHeadContestYear,
    useHeadContestDetail,
    useHeadPlan,
    useHeadPlanDetail,
    useHeadReview,
    useHeadReviewDetail,
    useHeadSekou,
    useHeadSekouWithMeta,
    useHeadSekouArea,
    useHeadSekouKeyword,
    useHeadSekouDetail,
    useHeadHomePage,
  };
}
